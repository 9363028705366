:root {
  --main-color: #474747;
  --alt-color: #2456c7;
}

body {
  font-family: "DM-Sans", sans-serif;
  font-weight: 400;
}

input::placeholder {
  font-family: "DM Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  font-family: "DM Sans", sans-serif;
}

p {
  font-family: "DM Sans";
}

/* Button */
.plotter-btn {
  background-color: #3b5caa;
}

/* Text Color */
.main-color {
  color: var(--main-color);
}

.alt-color {
  color: var(--alt-color);
}

.input-wrapper {
  display: inline-block;
  position: relative;
}
.input {
  width: 600px;
}

.button {
  border-radius: 0.25rem;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

/* Hero */
.hero-image-wrapper {
  background-image: url("./bacgrounds/bg-2.png");
  background-position: 0 120px;
  background-repeat: no-repeat;
  background-color: #3b5caa;
  height: 500px;
  width: 407px;
  right: 50px;
}

.hero-image {
  bottom: -4em;
  left: -2em;
  height: 500px;
}

.hero-header {
  font-size: 64px;
  width: 780px;
  font-weight: 500;
}

/* Who we are */
.rocket-img-wrapper {
  top: -80px;
  right: -100px;
}

/* Features */
.features-image-wrapper {
  background-color: #fce16c;
  height: 300px;
  width: 407px;
  right: 100px;
}

.features-image {
  height: 400px;
  top: -50px;
}

.more-features-image-wrapper {
  background-color: #2456c7;
  height: 300px;
  width: 407px;
}

.more-features-image {
  height: 400px;
  top: -50px;
}

/* Footer */
.footer-icons {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 10px;
  color: #fff;
  margin: auto;
  text-align: center;
}

.linkedin {
  background-color: #3b5998;
}

.instagram {
  background: linear-gradient(#e66465, #9198e5);
}

.twitter {
  background-color: #1da1f2;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .circle-svg {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .nav,
  .hr-nav {
    display: none !important;
  }

  .hero-header {
    font-size: 30px;
    width: fit-content;
  }

  .hero-image-wrapper {
    width: 310px;
  }

  /* Drum roll */
  .dr-text {
    margin-top: 50px;
  }

  .features-image-wrapper {
    width: 310px;
    right: 0;
  }

  /* More Features */
  .more-features-image-wrapper {
    max-width: 310px;
  }

  .circle-svg {
    display: none;
  }
}
